var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.showPopup
      ? _c(
          "div",
          {
            staticClass: "popup-wrapper",
            on: {
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                ) {
                  return null
                }
                return _vm.closeOnEscape($event)
              },
            },
          },
          [
            _c("div", {
              staticClass: "popup-overlay",
              on: { click: _vm.hide },
            }),
            _c("div", { staticClass: "popup-content" }, [
              _c("div", { staticClass: "popup-header" }, [
                _c("h5", [
                  _vm._v(
                    " " +
                      _vm._s(
                        "" +
                          _vm.$t("Conteúdo da Nota Fiscal") +
                          (_vm.invoiceSelected.validatedBy
                            ? " - " +
                              _vm.$t("Manifestado por") +
                              " " +
                              _vm.invoiceSelected.validatedBy.name +
                              " " +
                              _vm.$t("em") +
                              " " +
                              _vm.$options.filters.datetime(
                                _vm.invoiceSelected.validatedAt
                              )
                            : "")
                      ) +
                      " "
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "popup-body" },
                [
                  _vm.showLoading
                    ? _c("e-spinner")
                    : _c("iframe", {
                        attrs: {
                          src: _vm.pdfData,
                          width: "100%",
                          height: "99%",
                        },
                      }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "popup-footer" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-1",
                      attrs: { variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.hide()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Fechar")) + " ")]
                  ),
                  _vm.invoiceSelected && !_vm.invoiceSelected.invoiceReason
                    ? _c("e-button", {
                        attrs: {
                          variant: "primary",
                          busy: _vm.showLoading,
                          text: _vm.$t("Manifestar"),
                        },
                        on: {
                          click: function ($event) {
                            return _vm.acknowledgeInvoice()
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("invoice-knowledge", {
              ref: "invoiceKnowledgeModal",
              on: {
                "after-confirm": function ($event) {
                  _vm.showPopup = false
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }