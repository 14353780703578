var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-knowledge",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        size: "md",
        centered: "",
      },
      on: { hidden: _vm.onHideModal },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("Manifestar Nota Fiscal") +
                        " (Id: " +
                        _vm.form.id +
                        ")"
                    ) +
                    " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        { ref: "knowledgeForm", attrs: { name: "knowledgeForm" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "knowledge_modal-reason",
                          type: "vue-select",
                          options: _vm.invoiceReceivedReasonOptions(),
                          label: _vm.$t("Motivo do Nota Fiscal"),
                        },
                        on: { input: _vm.onSelectNatureOperation },
                        model: {
                          value: _vm.form.invoiceReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "invoiceReason", $$v)
                          },
                          expression: "form.invoiceReason",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.invoiceReason &&
                  _vm.form.invoiceReason !== "NotRecognized"
                    ? _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("e-management-account", {
                            attrs: {
                              id: "knowledge_modal-management-account",
                              required: "",
                              label: _vm.$t("Conta Gerencial"),
                              "validation-messages": {
                                required: _vm.$t("Este campo é obrigatório"),
                              },
                            },
                            model: {
                              value: _vm.form.managementAccountId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "managementAccountId", $$v)
                              },
                              expression: "form.managementAccountId",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.invoiceReason
                    ? _c(
                        "b-col",
                        {
                          staticClass: "d-flex flex-column",
                          attrs: { md: "12" },
                        },
                        [
                          _c("FormulateInput", {
                            attrs: {
                              id: "knowledge_modal-observation",
                              type: "textarea",
                              label: _vm.$t("Observação"),
                              instruction: _vm.$t(
                                "A observação informada aqui será incluída na despesa que será criada"
                              ),
                            },
                            model: {
                              value: _vm.form.observation,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "observation", $$v)
                              },
                              expression: "form.observation",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      staticStyle: { gap: "10px" },
                    },
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                          variant: "danger",
                        },
                        on: { click: _vm.onHideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          busy: _vm.busy,
                          text: _vm.$t("Confirmar"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }