<template>
  <b-modal
    id="modal-knowledge"
    no-close-on-backdrop
    hide-header-close
    hide-footer
    size="md"
    centered
    @hidden="onHideModal"
  >
    <template #modal-title>
      <p class="h4">
        {{ `${$t('Manifestar Nota Fiscal')} (Id: ${form.id})` }}
      </p>
    </template>

    <FormulateForm
      ref="knowledgeForm"
      name="knowledgeForm"
    >
      <b-container>
        <b-row align-h="center">
          <b-col
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="knowledge_modal-reason"
              v-model="form.invoiceReason"
              :type="'vue-select'"
              :options="invoiceReceivedReasonOptions()"
              :label="$t('Motivo do Nota Fiscal')"
              @input="onSelectNatureOperation"
            />
          </b-col>
          <b-col
            v-if="form.invoiceReason && form.invoiceReason !== 'NotRecognized'"
            md="12"
          >
            <e-management-account
              id="knowledge_modal-management-account"
              v-model="form.managementAccountId"
              required
              :label="$t('Conta Gerencial')"
              :validation-messages="{
                required: $t('Este campo é obrigatório'),
              }"
            />
          </b-col>
          <b-col
            v-if="form.invoiceReason"
            md="12"
            class="d-flex flex-column"
          >
            <FormulateInput
              id="knowledge_modal-observation"
              v-model="form.observation"
              type="textarea"
              :label="$t('Observação')"
              :instruction="
                $t('A observação informada aqui será incluída na despesa que será criada')
              "
            />
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col
            class="d-flex justify-content-end"
            style="gap: 10px"
          >
            <e-button
              :text="$t('Voltar')"
              :text-shortcuts="['ESC']"
              variant="danger"
              @click="onHideModal"
            />
            <e-button
              variant="primary"
              type="submit"
              :busy="busy"
              :text="$t('Confirmar')"
              @click="onConfirm"
            />
          </b-col>
        </b-row>
      </b-container>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { BModal, BContainer, BRow, BCol } from 'bootstrap-vue'
import EButton from '@/views/components/EButton.vue'
import { formulateHelper, invoiceDomains } from '@/mixins'
import { mapActions, mapState } from 'vuex'
import EManagementAccount from '@/views/components/inputs/EManagementAccount.vue'

export default {
  components: { BModal, BContainer, BRow, BCol, EButton, EManagementAccount },

  mixins: [formulateHelper, invoiceDomains],

  data() {
    return {
      busy: false,
      modalResolve: null,
      modalReject: null,
      saleData: null,
      form: {
        id: null,
        invoiceReason: null,
        managementAccountId: null,
        observation: null,
      },
    }
  },

  computed: {
    ...mapState('pages/invoice/invoiceReceived', ['invoiceSelected']),
  },

  methods: {
    ...mapActions('pages/invoice/invoiceReceived', ['fetchInvoicePdf', 'acknowledgeInvoice']),

    async onConfirm() {
      // this.$emit('confirmed', { reason: this.form.reason, managementAccountId: this.form.managementAccountId })
      try {
        this.busy = true
        await this.acknowledgeInvoice({
          invoiceId: this.invoiceSelected.id,
          reason: this.form.invoiceReason,
          managementAccountId: this.form.managementAccountId,
          observation: this.form.observation,
        })
        this.$bvModal.hide('modal-knowledge')
        this.showSuccess({ message: this.$t('Nota manifestada com sucesso') })
        this.$emit('after-confirm')
      } catch (error) {
        this.showError({ error })
      } finally {
        this.busy = false
      }
    },

    resetForm() {
      this.form = {
        id: null,
        invoiceReason: null,
        managementAccountId: null,
      }
    },

    onHideModal() {
      this.$bvModal.hide('modal-knowledge')
    },

    show(id) {
      this.$bvModal.show('modal-knowledge')
      this.resetForm()
      this.form.id = id
      this.onFocusInput()
    },

    onFocusInput() {
      setTimeout(() => {
        this.focusInput('#knowledge_modal-reason')
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped></style>
