<template>
  <transition name="fade">
    <div
      v-if="showPopup"
      class="popup-wrapper"
      @keydown.esc="closeOnEscape"
    >
      <div
        class="popup-overlay"
        @click="hide"
      />
      <div class="popup-content">
        <div class="popup-header">
          <h5>
            {{
              `${$t('Conteúdo da Nota Fiscal')}${
                invoiceSelected.validatedBy
                  ? ` - ${$t('Manifestado por')} ${invoiceSelected.validatedBy.name} ${$t(
                    'em'
                  )} ${$options.filters.datetime(invoiceSelected.validatedAt)}`
                  : ''
              }`
            }}
          </h5>
        </div>
        <div class="popup-body">
          <e-spinner v-if="showLoading" />
          <iframe
            v-else
            :src="pdfData"
            width="100%"
            height="99%"
          />
        </div>
        <div class="popup-footer">
          <b-button
            variant="danger"
            class="mr-1"
            @click="hide()"
          >
            {{ $t('Fechar') }}
          </b-button>
          <e-button
            v-if="invoiceSelected && !invoiceSelected.invoiceReason"
            variant="primary"
            :busy="showLoading"
            :text="$t('Manifestar')"
            @click="acknowledgeInvoice()"
          />
        </div>
      </div>
      <invoice-knowledge
        ref="invoiceKnowledgeModal"
        @after-confirm="showPopup = false"
      />
    </div>
  </transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { BButton } from 'bootstrap-vue'
import { ESpinner, EButton } from '@/views/components'
import InvoiceKnowledge from './InvoiceKnowledge.vue'

export default {
  components: {
    BButton,
    ESpinner,
    EButton,
    InvoiceKnowledge,
  },
  props: {},
  data() {
    return {
      showPopup: false,
      showLoading: false,
      invoiceId: null,
      pdfData: '', // Base64 string of PDF
    }
  },
  computed: {
    ...mapState('pages/invoice/invoiceReceived', ['invoicePdf', 'invoiceSelected']),
  },
  created() {
    window.addEventListener('keydown', this.handleKeydown)
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleKeydown)
  },
  methods: {
    ...mapActions('pages/invoice/invoiceReceived', ['fetchInvoicePdf', 'acknowledgeInvoice']),
    async show(invoiceId) {
      this.invoiceId = invoiceId
      this.showPopup = true
      try {
        this.showLoading = true
        await this.fetchInvoicePdf(invoiceId)
        if (this.invoicePdf) {
          this.pdfData = `data:application/pdf;base64,${this.invoicePdf}`
        } else {
          this.showError({ message: this.$t('Não foi possível obter o conteúdo da Nota Fiscal') })
        }
      } catch (error) {
        this.showError({ error })
        this.showPopup = false
      } finally {
        this.showLoading = false
      }
    },
    hide() {
      this.showPopup = false
    },
    handleKeydown(event) {
      if (event.key === 'Escape' && this.showPopup) {
        this.hide()
      }
    },
    closeOnEscape(event) {
      if (event.key === 'Escape') {
        this.hide()
      }
    },
    async acknowledgeInvoice() {
      this.$refs.invoiceKnowledgeModal.show(this.invoiceSelected.id)
    },
  },
}
</script>

<style scoped>
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1032;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.popup-content {
  position: relative;
  width: 90%;
  height: 90%;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
}

.popup-header {
  padding: 15px 10px;
  background: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.popup-header h5 {
  margin: 0;
}

.popup-body {
  padding: 20px;
  height: calc(100% - 100px);
  overflow-y: auto;
}

.popup-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background: #f0f0f0;
  border-top: 1px solid #ccc;
  text-align: right;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
</style>
